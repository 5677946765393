<template>
  <div class="group-sports" v-if="sports?.length">
    <div class="group-sports__list">
      <div class="group-sports__heading">
        <atomic-cat-heading
          :icon="getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.sports.icon')"
        >
          {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.sports.label') }}
        </atomic-cat-heading>

        <span class="group-sports__btn-show-all" @click="openSports">
          {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'cardsGroup.moreButton') }}
        </span>
      </div>

      <client-only>
        <div ref="sliderNode" class="group-sports__viewport" v-if="isMobile">
          <div class="group-sports__container">
            <div v-for="sport in sports" :key="sport.id" class="group-sports__slide">
              <card-sport :sport-info="sport" />
            </div>
          </div>
        </div>
      </client-only>

      <client-only>
        <template v-if="!isMobile">
          <button-arrows
            v-if="showArrowButtons"
            :prev-disabled="prevDisabled"
            :next-disabled="nextDisabled"
            @click-action="clickAction"
          />

          <div
            ref="scrollContainer"
            class="group-sports__items"
            :class="{ 'disabled-scroll-block': !sports?.length }"
            @scroll="scrollHandler"
          >
            <card-sport v-for="sport in sports" :key="sport.id" :sport-info="sport" />
          </div>
        </template>
      </client-only>
    </div>
  </div>
</template>

<script setup lang="ts">
  import emblaCarouselVue from 'embla-carousel-vue';
  import Autoplay from 'embla-carousel-autoplay';

  const autoplayOptions = {
    delay: 4000,
    stopOnInteraction: false,
    stopOnMouseEnter: true,
  };

  const [sliderNode] = emblaCarouselVue(
    {
      loop: true,
      align: 'start',
    },
    [Autoplay(autoplayOptions)]
  );

  import type { ISportsGroup } from '~/types';
  import { sportsList } from '@skeleton/core/consts/sportsConfig';

  interface IProps {
    maxItems?: number;
    showArrows?: boolean;
  }

  const props = withDefaults(defineProps<IProps>(), {
    maxItems: 14,
    showArrows: true,
  });

  const globalStore = useGlobalStore();
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = storeToRefs(globalStore);

  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  const { getContent } = useProjectMethods();

  const OFFSET_MARGIN = 20;
  const {
    public: { gamehubCdn },
  } = useRuntimeConfig();

  const sports = computed((): Maybe<ISportsGroup['sportsList']> => {
    const contentSportsList: Maybe<ISportsGroup['sportsList']> = getContent(
      globalComponentsContent.value,
      defaultLocaleGlobalComponentsContent.value,
      'sportsGroup.sportsList'
    );

    if (!contentSportsList || !contentSportsList.length) {
      return [];
    }

    return contentSportsList
      .map(sport => {
        const bg = gamehubCdn + sportsList.find(item => item.id === sport.id)?.cardBgUrl;
        const img = gamehubCdn + sportsList.find(item => item.id === sport.id)?.cardImgUrl;

        if (!bg || !img) {
          return null;
        }

        return {
          ...sport,
          background: bg,
          image: img,
        };
      })
      .filter(item => !!item);
  });

  const scrollHandler = (): void => {
    if (!scrollContainer.value) return;
    const { scrollLeft, offsetWidth, scrollWidth } = scrollContainer.value;

    prevDisabled.value = scrollLeft === 0;
    nextDisabled.value =
      scrollWidth < scrollLeft + offsetWidth + OFFSET_MARGIN && scrollWidth > scrollLeft + offsetWidth - OFFSET_MARGIN;
  };

  const clickAction = (direction: string): void => {
    const { offsetWidth, scrollWidth, scrollLeft } = scrollContainer.value;
    const widthToEnd = scrollWidth - (scrollLeft + offsetWidth);
    const scrollLeftValue = widthToEnd < offsetWidth ? widthToEnd : offsetWidth;
    const scrollRightValue = scrollLeft < offsetWidth ? scrollLeft : offsetWidth;
    scrollContainer.value.scrollBy({
      left: direction === 'next' ? scrollLeftValue : -scrollRightValue,
      behavior: 'smooth',
    });
  };

  const observerLoadMore = ref();
  const { initObserver } = useProjectMethods();
  const scrollContainer = ref();
  const loadMore = ref();
  const prevDisabled = ref(true);
  const nextDisabled = ref(true);
  const showArrowButtons = computed(() => props.showArrows && (!prevDisabled.value || !nextDisabled.value));

  onMounted(async () => {
    observerLoadMore.value = initObserver({
      settings: { root: scrollContainer.value, rootMargin: '90%', threshold: 0 },
    });

    await nextTick();
    if (props.showArrows) {
      setTimeout(() => {
        scrollHandler();
      }, 0);
    }
  });

  onBeforeUnmount(() => {
    if (loadMore.value && observerLoadMore.value) {
      observerLoadMore.value.unobserve(loadMore.value);
    }
  });

  const { localizePath } = useProjectMethods();

  const openSports = async (): Promise<void> => {
    await navigateTo({
      path: localizePath(`/sport`),
    });
  };
</script>

<style src="~/assets/styles/components/group/sport-cards.scss" lang="scss" />
